'use strict';

function addReviewsForReviewsPage(btn) {
    let reviews = $('.reviews-container');
    let $btn = $(btn);

    applyLoaderToBtn($btn);

    $.ajax({
        url: $btn.attr('href') + '?offset=' + $btn.data('offset') + '&limit=' + $btn.data('limit'),
        type: 'GET',
        success: function (response) {
            removeLoaderFromBtn($btn);
            $btn.attr('style', 'display:flex');
            if (response && response.length > 0) {
                $btn.data(
                    'offset',
                    parseInt($btn.data('offset')) + response.length
                );
                buildReviewsWidgets(reviews, response);
            }

            if (response && response.length < parseInt($btn.data('limit'))) {
                $btn.addClass('no-display');
            }
        },
        error: function (response) {
            removeLoaderFromBtn($btn);
        },
    });
}

function buildReviewsWidgets($reviews, reviewsData) {
    $.each(reviewsData, function (index, value) {
        let rating = '';

        for (let i = 0; i < value.rating; i++) {
            rating += '<li><i class="star-icon"></i></li>';
        }

        const review = `
            <div class='review'>
                <div class='review-main'>
                    <div class='review-title'>${value.title}</div>
                    <ul class='stars'>
                        ${rating}
                    </ul>
                    <div class='review-text'>${value.review}</div>
                </div>
                <div class='review-footer'>
                    <div class='review-created'>${value.date}</div>
                    <div class='review-author'>Reviewed by ${
                        value.first_name || ''
                    } ${value.last_name_short || ''}
                        ${
                            value.hostLink
                                ? `, <span>hosted by <a class='host-link' href='${value.hostLink}' target='_blank' rel='noopener'>${value.itinerary.title}</a></span>`
                                : ''
                        }
                    </div>
                </div>
            </div>`;
        $reviews.append(review);
    });
}
