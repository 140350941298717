function showSpinner() {
	const spinner = `<div class="box-spinner">
            <i class="box-spinner-icon fa fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true"></i>
        </div>`;
	document.body.insertAdjacentHTML('afterbegin', spinner);
}

function deleteSpinner() {
	document.querySelector('.box-spinner').remove();
}
