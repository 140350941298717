'use strict';

let TravelAgentRegistration = function() {
	this.selector = '#save-travel-agent';
	let iti;
	
	this.init = function() {
		initPhoneCode();

		$('#commission_model').on('change', function (e) {
			if (e.target.value === 'accumulated') {
				$('#payee-region-block').show();
			} else {
				$('#payee-region-block').hide();
				$('#payee_region').val('');
				$('.group-field.commission').find('.js-additional').remove();
			}
		});

		$('#payee_region').on('change', getBankAccountRegionForm);

		const registAgentSection = document.querySelector('.regist-agent');

		registAgentSection.querySelector(this.selector).addEventListener('click', function (e) {
			checkValidation();

			if (showJSValidationError()) {
				return false;
			}
			add(e);
		});

		$('input[type=radio]').each(function() {
			$(this).on('change', function() {
				$(this).parents('.notification').find('.form-alert-danger').text('');
			});
		});
	};
	const showJSValidationError = function () {
		let showError = false;

		$('.form-alert-danger').each(function () {
			if ($(this).text().length) {
				$([document.documentElement, document.body]).animate({
					scrollTop: parseInt($('#' + $(this).attr('id')).offset().top, 10) - 160
				}, 500);
				showError = true;
			}
		});

		return showError;
	};
	let add = function(e) {
		showSpinner();

		let form = $(e.target).closest('form');
		let formData = {};

		form.serializeArray().map(input => (formData[input.name] = input.value));
		formData.phone_code = iti.getSelectedCountryData().dialCode;
		formData.is_tc_accepted = $('#is_tc_accepted').prop('checked') ? $('#is_tc_accepted').val() : 0;

		form.find('.form-alert-danger').text('');

		$.ajax({
			url: form.attr('action'),
			type: form.attr('method'),
			data: formData,
			dataType: 'json',
			success: function(response) {
				deleteSpinner();

				if (typeof response.message !== 'undefined') {
					$('.regist-agent')
						.text('')
						.append(`
                            <p class="success-message">${response.message}</p>
                            <section class="see__more">
                                <a href="/destinations" class="btn translatable">See all destinations</a>
                            </section>
                        `);
					window.scrollTo({
						top: 0,
						behavior: 'smooth',
					});
				}
			},
			error: function(response) {
				deleteSpinner();

				if (typeof response.responseJSON === 'undefined') {
					return;
				}
				if (typeof response.responseJSON.message !== 'undefined') {
					form.find('.form-alert-danger.general-error-message').text(response.responseJSON.message);
				}
				if (typeof response.responseJSON.data === 'object') {
					let data = response.responseJSON.data;
					for (let name in data) {
						let errorSpan = form.find('.form-alert-danger.' + name);
						errorSpan.text(data[name]);
					}
				}
			},
		});
	};

	const initPhoneCode = function() {
		const input = document.querySelector('#phone_code');
		iti = intlTelInput(input, {
			initialCountry: 'auto',
			geoIpLookup: function(success, failure) {
				$.get('https://ipinfo.io', function() {}, 'jsonp').always(function(resp) {
					var countryCode = resp && resp.country ? resp.country : '';
					success(countryCode);
				});
			},
		});
	};

	const checkValidation = function() {
		validateField(document.getElementById('first_name'), getLengthValidationErrorMessage, {'min' : 2, 'max' : 70});
		validateField(document.getElementById('last_name'), getLengthValidationErrorMessage, {'min' : 2, 'max' : 70});
		validateField(document.getElementById('agency_name'), getLengthValidationErrorMessage, {'min' : 2, 'max' : 70});
		validateField(document.getElementById('travel_industry_id'), getLengthValidationErrorMessage, {'min' : 0, 'max' : 70});
		validateField(document.getElementById('job_title'), getLengthValidationErrorMessage, {'min' : 2, 'max' : 70});
		validateField(document.getElementById('country_residence'), getLengthValidationErrorMessage, {'min' : 2, 'max' : 70});
		validateField(document.getElementById('city_residence'), getLengthValidationErrorMessage, {'min' : 2, 'max' : 70});
		validateField(document.getElementById('phone_number'), getNumberValidationErrorMessage);
		validateField(document.getElementById('email'), getEmailValidationErrorMessage);
		validateField(document.getElementById('password'), getLengthValidationErrorMessage, {'min' : 8, 'max' : 16});
		validateField(document.getElementById('password_confirmation'), validateIdentical, {'linkField' : '#password'});
		validateField(document.getElementById('destinations'), getLengthValidationErrorMessage, {'min' : 2, 'max' : 320});
		validateField(document.getElementById('how_did_you_hear_about_us'), getLengthValidationErrorMessage, {'min' : 2, 'max' : 250});
		validateField(document.getElementById('radio-section'), validateFieldRadioChecked);
	};

	/**
	 * @param e (js event)
	 */
	const getBankAccountRegionForm = function(e) {
		const $form = $(e.target).closest('form');
		const targetBlock = $('.group-field.commission');
		targetBlock.find('.js-additional').remove();
		const payeeRegion = e.target.value;
		if (!payeeRegion.length) {
			return;
		}
		showSpinner();
		$.ajax({
			url: '/api/travel-agents/registration/getBankDetailsForm?payee_region=' + payeeRegion,
			type: 'GET',
			success: function (response) {
				targetBlock.append($(response));
				targetBlock.find('input[type="text"]').on('blur', (event) => validateField(event.target, getLengthValidationErrorMessage, {'min' : 2, 'max' : 70}));
				targetBlock.find('input[type="email"]').on('blur', (event) => validateField(event.target, getEmailValidationErrorMessage));
				deleteSpinner();
			},
			error: function (response) {
				deleteSpinner();
				if (typeof response.responseJSON === 'undefined') {
					return;
				}
				if (typeof response.responseJSON.message !== 'undefined') {
					$form.find('.error-message.general-form').text(response.responseJSON.message);
				}
			}
		});
	};
};

new SelectorInitiator().setObject(new TravelAgentRegistration());
