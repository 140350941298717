'use strict';

const ProgressiveBar = function(item, total='') {
    this.selector = '.progressive-bar';
    this.currentPosition = '.js-current-position';
    this.totalPosition = '.js-total-position';
    this.indication = '.progressive-bar_progress-indicator';
    this.item = item;
    this.total = total;
    let self = this;

    this.init = function() {
        new SelectorInitiator().getObjectBySelector('.see-more').loadExperienceObserver().subscribe((response) => {
            self.total = response.total;
            let count = $(self.item).length + response.data.length;

            startProgressBar(count, self.total);
        });
    };


    /**
     * shows progressBar status and counting current & end status positions;
     *
     * @var count {number} - current quantity cards at the page (for start counting);
     * @var total {number} - total cards what we'll have (for end counting);
     *
     * @return void;
     */
    const startProgressBar = function(count, total) {
        $(self.selector).removeClass('no-display');
        $(self.currentPosition).text(count);
        $(self.totalPosition).text(total);

        let indicatorWidth = Number($(self.indication).parent().css('width').replace('px', ''));
        let progressPercents = count / total;
        let progressIndicateWidth = indicatorWidth * progressPercents;

        $(self.indication).css({'width': progressIndicateWidth + 'px'});
    };
};

new SelectorInitiator().setObject(new ProgressiveBar('.js-single-experience'));