// add review for hosts and experiences

function addReview(btn) {

    var $btn = $(btn);
    var $form = $btn.parents('form:first');

    var formError = $form.find('#form_error');
    formError.attr('style','display: none');

    if (!isValidReviewForm($form)) {
        return false;
    }

    applyLoaderToBtn($btn);

    $.ajax({
        url: $form.attr('action'),
        type: 'POST',
        data: $form.serializeArray(),
        dataType: 'json',
        success: function () {
            location.reload();
        },
        error: function (response) {
            removeLoaderFromBtn($btn);
            if (response.status === 404 && response.responseJSON.message) {
                formError.attr('style','display: block;');
                formError.find('p').text(response.responseJSON.message);
            } else if (response.responseJSON) {
                formError.attr('style','display: block');
                formError.find('p').text(Object.values(response.responseJSON).join(", "));
            }
        }
    });
}

function isValidReviewForm($form) {

    if ($form.find('input[name="first_name"]').get(0) && !validateField($form.find('input[name="first_name"]').get(0), getLengthValidationErrorMessage, {'max' : 25})) {
        return false;
    }
    if ($form.find('input[name="last_name"]').get(0) && !validateField($form.find('input[name="last_name"]').get(0), getLengthValidationErrorMessage, {'max' : 25})) {
        return false;
    }
    if ($form.find('input[name="email"]').get(0) && !validateField($form.find('input[name="email"]').get(0), getEmailValidationErrorMessage)) {
        return false;
    }
    if ($form.find('input[name="title"]').get(0) && !validateField($form.find('input[name="title"]').get(0), getLengthValidationErrorMessage, {'max' : 75})) {
        return false;
    }
    if (!validateField($form.find('[name="review"]').get(0), getLengthValidationErrorMessage, {'max' : 2500})) {
        return false;
    }

    return true;
}
