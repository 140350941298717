'use strict';

const RotateHostsNames =  function (config) {

    const defaultSelector = '.hosts__title';
    this.selector = config.hasOwnProperty('selector') ? config.selector : defaultSelector;
    this.speedShow = config.hasOwnProperty('speedShow') ? config.speedShow : 1;
    this.interval = config.hasOwnProperty('interval') ? config.interval : 1;
    this.counter = config.hasOwnProperty('counter') ? config.counter : 4;

    this.insertHostName = function() {

        const itemsTitle = $(this.selector).children('span');
        let self = this;

        setInterval(function () {

            for (let item of itemsTitle) {

                if (self.counter === hostNames.length) {
                    self.counter = 0;
                }

                $(item).html(hostNames[self.counter++]).fadeOut(1).fadeIn(self.speedShow*1000);
            }
        }, self.interval*1000);
    };

    this.init = function() {
        if (this.counter >= hostNames.length) {
            this.counter = 0;
        }

        this.insertHostName();
    };
};

    new SelectorInitiator().setObject(new RotateHostsNames({
        selector: '.hosts__title',
        speedShow: 2.5,
        interval: 5,
        counter: 3
    }));