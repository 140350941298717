// open links in new or the same tab
let OpenLinks = function () {
    this.selector = '.card-tile';

    this.init = function () {
        if (!isMobile) {
            $(this.selector).each(function() {
                $(this).attr('target', '_blank');
            });
        }
    };
};

new SelectorInitiator().setObject(new OpenLinks());