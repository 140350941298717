const animationCircle = function() {
    this.selector = '#animationCircle';
    this.init = isMobile ? function() {} : initAnimationModul;
};

new SelectorInitiator().setObject(new animationCircle());

let timerId;
function initAnimationModul() {
    let toggle;
    function scrollWindowAnimation() {
        const positionScroll = window.pageYOffset;
        const heightWindow = window.innerHeight;

        positionScroll < heightWindow ? (clearTimeout(timerId), (toggle = true)) : null;
        positionScroll > heightWindow && toggle ? (animate(), (toggle = false)) : null;
    }

    if ($('#animationCircle').css('opacity') > 0) {
        $('#animationCircle').width(document.documentElement.clientWidth);
        $('#animationCircle').height(
            $('.wrapper').height() -
                ($('.form-subscribe').height() +
                    $('.section-dark').height() +
                    $('.footer').height() +
                    $('.youtube-block').height())
        );
        // anim array of animation Objects
        // w  - width of anim container
        // h - height of anim container
        var anim = [],
            w = $('#animationCircle').width(),
            h = $('#animationCircle').height();

        // set animations object start positions
        for (var item = 0; item < animationSettings.animationQuantity; item++) {
            $('#source' + (item % 3))
                .clone()
                .addClass('animation')
                .css({
                    position: 'absolute',
                    display: 'block',
                    width: Math.round(w / animationSettings.animationSize),
                    height: Math.round(w / animationSettings.animationSize),
                    'z-index': 9999,
                })
                .appendTo('#animationCircle');
        }

        // creating animation objects
        $('.animation').each(function(index, obj) {
            var x = Math.round((w - w / animationSettings.animationSize) * Math.random(1)),
                y = index * Math.round(w / animationSettings.animationSize + 100);
            $(obj).css({ left: x, top: y });
            anim.push(new AnimationObj(x, y, obj));
        });
        // init animation on first scroll

        scrollWindowAnimation();
        document.addEventListener('scroll', scrollWindowAnimation, {passive: true});
    }

    //Animation start and collision calculation
    function animate() {
        timerId = setTimeout(animate, 1000 / 60);

        for (let i = 0; i < anim.length; ++i) {
            anim[i].step();
        }

        for (let k = 0; k < anim.length; ++k) {
            let p1 = anim[k];

            for (let j = k + 1; j < anim.length; ++j) {
                let p2 = anim[j],
                    dx = p1.x - p2.x,
                    dy = p1.y - p2.y,
                    d = dx * dx + dy * dy;

                if (Math.sqrt(d) <= p1.size) {
                    let vox = p1.vx,
                        voy = p1.vy;

                    p1.vx = p2.vx;
                    p1.vy = p2.vy;
                    p2.vx = vox;
                    p2.vy = voy;
                }
            }
        }
    }

    // constructor Animation Object
    function AnimationObj(x, y, item) {
        this.x = x;
        this.y = y;
        this.item = item;
        this.size = Math.round(w / animationSettings.animationSize);
        this.width = Math.round(w / animationSettings.animationSize);
        let vel = animationSettings.animationSpeed,
            rad = Math.PI * 2 * Math.random();

        this.vx = Math.round(Math.cos(rad) * vel);
        this.vy = Math.round(Math.sin(rad) * vel);

        this.vel = vel;
        this.rad = rad;
    }

    // step of animation
    AnimationObj.prototype.step = function() {
        this.x += this.vx;
        this.y += this.vy;

        if (this.x < 0) {
            this.x = 0;
            this.vx *= -1;
            this.setRad();
        } else if (this.x > w - this.width) {
            this.x = w - this.width;
            this.vx *= -1;
            this.setRad();
        }

        if (this.y < 0) {
            this.y = 0;
            this.vy *= -1;
            this.setRad();
        } else if (this.y > h - this.width) {
            this.y = h - this.width;
            this.vy *= -1;
            this.setRad();
        }

        $(this.item).css({ left: this.x, top: this.y });
    };

    //direction vector
    AnimationObj.prototype.setRad = function() {
        this.rad = Math.atan(this.vy / this.vx);

        if (this.vx < 0) {
            this.rad += Math.PI;
        }

        if (this.vx !== 0) {
            this.vel = Math.round(this.vx / Math.cos(this.rad));
        } else {
            this.vel = Math.round(this.vy / Math.sin(this.rad));
        }
    };
}
