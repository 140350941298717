'use strict';

const PinterestSocialShareScript = function() {
    this.selector = '.js-pinterest-share';
    let self = this;
    this.init = function () {
        $(window).one('scroll', function() {
            createSharedPicture();
            startPinterestScript();
        });
    };

    const createSharedPicture = function() {
        $(self.selector).find('a')
                        .attr('data-pin-description', $('head').children('meta[property="og:description"]').attr('content'))
                        .attr('data-pin-media', $('head').children('meta[property="og:image"]').attr('content'));
    };

    function startPinterestScript() {
        let head = $('head');
        let sharePinterest = document.createElement('script');
        sharePinterest.async = true;
        sharePinterest.defer = true;
        sharePinterest.src='//assets.pinterest.com/js/pinit.js';
        sharePinterest.nonce = nonce;
        head.append(sharePinterest);

        $(self.selector).find('a').attr('href', 'https://www.pinterest.com/pin/create/button/')
                                  .removeClass('disabled')
                                  .addClass('active');
    }
};

new SelectorInitiator().setObject(new PinterestSocialShareScript());


const FbSocialShare = function() {
    this.selector = '.js-fb-share';
    let self = this;

    this.init = function () {
        $(window).one('scroll', function() {
            addFacebookInitialization();
            startFbScript();
        });
    };

    function startFbScript(nc) {
        let shareFB = document.createElement('script');
        shareFB.crossorigin = 'anonymous';
        shareFB.async = true;
        shareFB.defer = true;
        shareFB.src = 'https://connect.facebook.net/en_US/sdk.js';
        shareFB.nonce = nonce;
        $('head').append(shareFB);

        $(self.selector).find('a')
                        .attr('href', prepareUrlForFbSHaring($(self.selector).attr('data-facebook')))
                        .removeClass('disabled')
                        .addClass('active');
    }

    const addFacebookInitialization = function () {
        window.fbAsyncInit = function() {
            FB.init({
                appId            : facebookAppId,
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v8.0'
            });
        };
    };

    /**
     * @return string
     */
    const prepareUrlForFbSHaring = function(url) {
        return 'https://www.facebook.com/sharer/sharer.php?u=' + url + '&amp;src=sdkpreparse';
    };
};

new SelectorInitiator().setObject(new FbSocialShare());