var LocalCacheService = {

    /**
     * @param key {string}
     * @param value {*}
     * @param lifetime {number} milliseconds, default 1 day
     * @return {boolean}
     */
    set : function (key, value, lifetime) {

        lifetime = lifetime || 86400000;
        if (typeof key !== 'string') {
            console.error('Key must be string');
            return false;
        }

        if (typeof lifetime !== 'number') {
            console.error('Lifetime must be number of milliseconds');
            return false;
        }

        localStorage.setItem(key, JSON.stringify({
            expiration : new Date().getTime() + lifetime,
            value: value
        }));

        return true;
    },

    /**
     * @param key
     * @return {*}
     */
    get : function (key) {

        if (typeof key !== 'string') {
            console.error('Key must be string');
            return false;
        }

        var stored = localStorage.getItem(key);

        if (stored === null) {
            return stored;
        }

        if (typeof stored !== 'string') {
            return null;
        }

        var parsedStored = JSON.parse(stored);

        if (typeof parsedStored.value === 'undefined' || typeof parsedStored.expiration !== 'number') {
            return null;
        }

        if (parsedStored.expiration < new Date().getTime()) {
            localStorage.removeItem(key);
            return null;
        }

        return parsedStored.value;
    }
};
