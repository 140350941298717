'use strict';

const ReadFullText = function() {
    this.selector = '.full-text';
    this.init = function() {
        checkShortText();
        isReadFullText() ? document.querySelector('.read-full-text-btn').addEventListener('click', OpenCloseFullText) : null;
    };

    const OpenCloseFullText = function() {
        document.querySelector('.full-text').classList.toggle('open');
        this.classList.toggle('opened');
    };

    const checkShortText = function() {
        document.querySelector('.short-text') ? null : document.querySelector('.full-text').classList.add('open');
    };

    const isReadFullText = function() {
        return document.querySelector('.read-full-text-btn') ? true : false;
    };
};
new SelectorInitiator().setObject(new ReadFullText());


/**
 * controls for the Tabs buttons;
 */
const TabsBtnControls = function() {
    this.selector = '.tabs-list';
    let self = this;

    this.init = function() {
        $('.js-tabs-btn').on('click', openTabs);
    };

    const openTabs = function() {
        $(self.selector).find('.js-tabs-btn')
                        .removeClass('open');

        $(self.selector).find('.active')
                        .addClass('no-display')
                        .removeClass('active');

        $(self.selector).find($('[data-value=' + $(this).val() +']'))
                        .addClass('active')
                        .removeClass('no-display');

        $(self.selector).find('.no-decor-line')
                        .removeClass('no-decor-line');

        $(this).addClass('open');

        $(this).parent()
               .prev()
               .children(':first-child')
               .addClass('no-decor-line');
    };
};

new SelectorInitiator().setObject(new TabsBtnControls());


/**
 * Experience photos slider activate;
 */
const ExperiencePhotosSlider = function() {
    this.selector = '.js-experience-photos-slider';
    let self = this;

    this.init = function() {
        if (!isScreenSizeMobile()) {
            $(window).one('scroll', function () {
                initExperiencePhotosSlider();
            });
        }
    };

    const initExperiencePhotosSlider = function() {
        $(self.selector).slick({
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        dots     : false,
                        focusOnSelect: true,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1
                    }
                }
            ]
        });
    };
};

new SelectorInitiator().setObject(new ExperiencePhotosSlider());