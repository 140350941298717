'use strict';

function applyLoaderToBtn($btn) {
    $btn.attr('disabled', 'disabled');
    $btn.attr('style', 'display:none');
    $btn.parent().append($('<div class="btn-loader"></div>'));
}

function removeLoaderFromBtn($btn) {
    $btn.removeAttr('disabled');
    $btn.parent().find('.btn-loader').remove();
    $btn.attr('style', 'display:inline-block');
}

function isElementVisibleOnScreen(element) {

    var belowTheFold = function (element) {
        return ( $(window).height() + $(window).scrollTop() ) > $(element).offset().top;
    };

    var aboveTheTop = function (element) {
        return $(window).scrollTop() < ( $(element).offset().top + $(element).height() );
    };

    return belowTheFold(element) && aboveTheTop(element);
}

//for masonry lib
function isScreenSizeMobile() { // this need for slider lib masonry. it`s check size window
    return $(window).width() > 767 ? true : false;
}

// breadcrumps on city experiences;
const Breadcrumps = function() {
    this.selector = '.white-bg';
    this.init = function() {
        const whiteBg = document.querySelector(this.selector);
        window.pageYOffset > 0 ? whiteBg.classList.add('white-bg-active') : null;
        window.addEventListener('scroll', function() {
            window.pageYOffset == 0 ? whiteBg.classList.remove('white-bg-active') : null;
            window.pageYOffset > 0 ? whiteBg.classList.add('white-bg-active') : null;
        }, {passive: true});
    };
};

new SelectorInitiator().setObject(new Breadcrumps());

const PageDestination = function() {
    this.selector = '#browse-experience';
    const browseExperience = document.querySelector(this.selector);
    this.init = function() {
        setAnchorForFilter();
    };

    const setAnchorForFilter = function() {
        if (localStorage.getItem('showFiltersResult')) {
            window.scrollTo(0, browseExperience.getBoundingClientRect().top - 70);
            localStorage.removeItem('showFiltersResult');
        }
    };
};
new SelectorInitiator().setObject(new PageDestination());

const TenThingsReadMore = function() {
    this.selector = '.js-ten-things_read-more';

    this.init = function() {
        if ($('.ten-things-section__list').children().length > 2) {
            $(this.selector).removeClass('no-display').on('click', function() {
                $(this).prev().toggleClass('collapsed-list');
            });
        }
    };
};

new SelectorInitiator().setObject(new TenThingsReadMore());


