const AddHostsWidgets = function () {
    this.selector = '.add-hosts';
    const loadConfig = {
        mobile_offset: 3,
        desktop_offset: 9
    };

    const self = this;
    const hosts = $('#hosts');

    this.init = function () {
        const $addHostBtn = $(self.selector);
        const hostCards = $('.host-card');

        $addHostBtn.on('click', function (e) {
            e.preventDefault();
            addHosts($(this));
        });

        if (!isMobile && (loadConfig.desktop_offset - hostCards.length) > 0) {
            $addHostBtn.attr('data-limit', loadConfig.desktop_offset - hostCards.length);
            $addHostBtn.attr('data-offset', hostCards.length);
            $addHostBtn.trigger('click');
        }
    };
    function addHosts($btn) {

        applyLoaderToBtn($btn);

        $.ajax({
            url: $btn.attr('href') + '?offset=' + $btn.attr('data-offset') + '&limit=' + $btn.attr('data-limit') + '&location=' + hosts.attr('data-location'),
            type: 'GET',
            success: function (response) {
                $btn.attr('data-limit', isMobile ? loadConfig.mobile_offset : loadConfig.desktop_offset);
                removeLoaderFromBtn($btn);
                if (response && response.length > 0) {
                    $btn.attr('data-offset', parseInt($btn.attr('data-offset'), 10) + response.length);
                    buildHostsWidgets(hosts, response);
                    new SelectorInitiator().getObjectBySelector('.image-height-variable').init();
                }

                if (parseInt($btn.attr('data-offset'), 10) >= parseInt($btn.attr('data-total'), 10)) {
                    $btn.remove();
                }
            },
            error: function (response) {
                removeLoaderFromBtn($btn);
            }
        }).then(function () {
            setTimeout(function () {
                $(window).trigger('resize').trigger('scroll');
            }, 500);
        });
    }

    function buildHostsWidgets($hosts, hostsData) {
        const colors = ['basic-green', 'basic-yellow', 'basic-red'];
        let colorKey = 0;

        $.each(hostsData, function(index, value) {

            if (colorKey >= colors.length) {
                colorKey = 0;
            }

            let color = colors[colorKey];

            let hostElement = $($hosts.children()[0]).clone(true);
            hostElement.find('img').attr({src: value.cloudinaryImages.widget, alt: value.first_name});
            hostElement.find('img').removeAttr('data-src');
            hostElement.find('[data-name=tagline]').html(value.tagline);
            hostElement.find('[data-name=bottom-link]').attr('href', value.link);
            hostElement.find('[href]').attr('href', value.link);

            if (!isMobile) {
                hostElement.find('[href]').attr('target', '_blank');
            }

            let title = hostElement.find('[data-name=title]');

            title.find('span').html(value.location.location + ', ' + value.country);
            title.find('span').removeClass('basic-green');
            title.find('span').addClass(color);
            title.find('strong').text(value.first_name);

            let starsElements  = hostElement.find('[data-name=stars]');
            starsElements.children().remove();

            let reviewsElement = hostElement.find('[data-name=reviews]');
            reviewsElement.text('');

            if (parseInt(value.reviewCount, 10) >= parseInt(starsElements.data('min'), 10)) {
                for (var i = 0; i < value.rating; i++) {
                    starsElements.append($('<li><i class="fa fa-star basic-yellow" aria-hidden="true"></i></li>'));
                }

                if (value.reviewCount && value.reviewCount > 0) {
                    let reviewEnding = value.reviewCount > 1 ? 's' : '';
                    reviewsElement.text('(' + value.reviewCount + ' review' + reviewEnding + ')');
                }
            }

            $hosts.append(hostElement);

            colorKey++;
        });
    }
};

new SelectorInitiator().setObject(new AddHostsWidgets());

let ImgIconCirclesVariable = function () {

    this.selector = '.icon-circles-variable';
    this.imageIcons = [
        [$('<i class="icon-article-circle11"></i>'), $('<i class="icon-article-circle12"></i>'), $('<i class="icon-article-circle13"></i>')],
        [$('<i class="icon-article-circle21"></i>'), $('<i class="icon-article-circle22"></i>'), $('<i class="icon-article-circle23"></i>')],
        [$('<i class="icon-article-circle31"></i>'), $('<i class="icon-article-circle32"></i>'), $('<i class="icon-article-circle33"></i>')],
    ]; // default from new design

    this.init = function () {

        let imageContainer = $(this.selector);
        let imageIcons = this.imageIcons;
        let sizeIndex = Math.ceil(imageContainer.find('img').length / imageIcons.length);

        const imageIconsCopy = imageIcons;
        let i = 0;
        while (i < sizeIndex - 1) {
            imageIcons = imageIcons.concat(imageIconsCopy);
            i++;
        }

        $.each(imageContainer, function (key, item) {
            let icons = imageIcons[key];
            let y = 0;
            let iconsHtml = '';
            while (y < icons.length) {
                iconsHtml += icons[y].get(0).outerHTML;
                y++;
            }

            let link = $(item).find('img').parent();
            link.find('i').remove();
            link.html(link.html() + iconsHtml);
        });
    };
};

new SelectorInitiator().setObject(new ImgIconCirclesVariable());